.searchBtn {
    padding: 4px 6px !important;
    color: #d3b574 !important;
}

.searchInput {
    display: inline-block;
}

.searchDropdown { 
    position: absolute;
    background-color: #E5E5E5;
    width: -webkit-fill-available;
    border-radius: 0.5rem;
    color: #7A7A7A;
}

.searchImg {
    max-width: 40px;
    margin-right: 10px;
    max-height: 50px; 
    border-radius: 4px;
    object-fit: contain;
}

.searchRes {
    color: #7A7A7A;
}

.searchRes:hover {
    color: #d3b574;
    cursor: pointer;
}